@import '../../themes/variables';

.product-detail-wrapper {
  display: flex;
  width: 100%;
  gap: 16px;

  @media (max-width: $switchMobileMax) {
    flex-direction: column;
  }

  .product-image-wrapper {
    flex: 0.5 0 0;
    position: relative;
  }

  .product-favorite {
    position: absolute;
    z-index: 2;
    top: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 0 1px 2px 1px rgb(0 0 0 / 10%);
    font-size: 18px;

    &.product-favorite-active {
      color: #ef624d;
    }
  }

  .product-detail {
    flex: 0.5 0 0;

    .product-brand {
      display: block;
      margin: 0 0 8px 0;
    }

    .product-title {
      display: block;
      padding: 0 24px 0 0;
    }

    .product-price {
      display: flex;
      margin: 0 0 8px 0;

      .product-price-initial {
        text-decoration: line-through;
        margin: 0 8px 0 0;
      }

      .product-price-final {
      }

      .product-discount {
        color: #ef624d;
        margin: 0 8px 0 0;
      }
    }

    .product-rate {
      margin: 0 0 8px 0;
    }

    .product-option-title {
      margin: 0 0 8px 0;
    }

    .product-variant {
      margin: 0 0 16px 0;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 8px;

        li {
          display: block;
          margin: 0;
          padding: calc((100% - 40px) / 6 * 1.5) 0 0 0;
          width: calc((100% - 40px) / 6);
          position: relative;
          cursor: pointer;

          div {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            border: 1px solid #cccccc;
            border-radius: 3px;

            img {
              max-width: 100%;
              max-height: 100%;
            }
          }

          &.product-variant-active {
            div {
              border-color: #5ab381;
            }
          }

          &.product-variant-more {
            div {
            }
          }
        }
      }
    }

    .product-size {
      margin: 0 0 16px 0;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 8px;

        li {
          display: block;

          &.product-size-active {
          }

          &.product-size-more {
            div {
            }
          }
        }
      }
    }

    .product-add-to-cart {
      margin: 0 0 16px 0;
      display: flex;

      .input-group {
        width: 100px;
        margin: 0 8px 0 0;

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
        }
      }
    }

    .product-warning {
      color: #ef624d;
      margin: 0 0 8px 0;
    }

    .product-out-of-stock-warning {
      color: #ef624d;
      margin: 0 0 8px 0;
    }

    .btn-size-secondary {
      border-color: #9a9a9a;
      background-color: #ffffff;
      color: rgb(33, 37, 41);
      min-width: 28px;
      padding: 0;
      text-align: center;
      height: 28px;
      font-size: 12px;
      outline: none;
      box-shadow: none;

      &:hover {
        color: rgb(33, 37, 41);
      }
    }

    .btn-size {
      border-color: #5ab381;
      background-color: #5ab381;
      color: #ffffff;
      min-width: 28px;
      padding: 0;
      text-align: center;
      height: 28px;
      font-size: 12px;
      outline: none;
      box-shadow: none;

      &:hover {
        color: #ffffff;
      }
    }
  }
}

.product-detail-more {
  margin: 0 0 16px 0;
  font-size: 14px;

  h2 {
    border-bottom: 3px solid #5ab381;
    padding: 8px 0;
    font-size: 16px;
    display: block;
  }
}

@media (max-width: $switchMobileMax) {
  .modal-product {
    position: relative;

    .btn-back {
      position: absolute;
      top: 0;
      left: 0;
    }

    .product-detail-wrapper {
      .product-image-wrapper {
        margin: -14px -14px 0 -14px;
        border-radius: 0;

        img {
          border-radius: 0;
        }
      }
    }
  }

  .modal-product-footer {
    width: 100%;
    display: flex;

    .input-counter {
      width: 90px;
    }
  }
}


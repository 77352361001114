@import '../../themes/variables';

.item-image-voltran {
  display: flex;
  gap: 16px;
  margin: 0 0 16px 0;
}

.item-image-thumbnail {
  height: 100%;
  width: 64px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  gap: 16px;

  > div {
    width: 100%;
    padding: 150% 0 0 0;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 3px;

    &.active {
      border: 1px solid #5ab381;
    }

    > div {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      > img {
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
      }
    }
  }
}

.item-image-wrapper {
  flex: 1;
  position: relative;
  background: #f4f4f4;

  @media (min-width: $switchDesktopMin) {
    border-radius: 3px;
  }
}

.item-image-slider {
  width: 100%;
  padding: 150% 0 0 0;
  border: none;
}

.item-image-navigation {

  .item-image-navigation-prev {
    position: absolute;
    top: calc(50% - 16px);
    left: 16px;
    z-index: 2;
    background: #ffffff;
    width: 32px;
    cursor: pointer;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 32px;
    box-shadow: 0 1px 2px 1px rgb(0 0 0 / 10%);
  }

  .item-image-navigation-next {
    position: absolute;
    top: calc(50% - 16px);
    right: 16px;
    z-index: 2;
    background: #ffffff;
    width: 32px;
    cursor: pointer;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 32px;
    box-shadow: 0 1px 2px 1px rgb(0 0 0 / 10%);
  }

  .item-image-navigation-list {
    position: absolute;
    bottom: 16px;
    left: 16px;
    z-index: 2;
    width: calc(100% - 32px);
    display: flex;
    justify-content: center;
    gap: 8px;

    > div {

      display: inline-block;
      width: 12px;
      height: 12px;
      background: #ffffff;
      text-indent: -10000px;
      cursor: pointer;
      border-radius: 50%;
      opacity: 0.6;

      &.active {
        background: #5ab381;
        opacity: 1;
      }
    }
  }
}

.item-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  gap: 16px;
  width: 100%;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  .item-image-holder {
    scroll-snap-align: start;
    flex-shrink: 0;
    flex-grow: 0;
    counter-increment: item;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 3px;
    overflow: hidden;

    img {
      max-width: 100%;
      max-height: 100%;
      margin: 0;
    }
  }
}

@import '../../themes/variables';

// Desktop
@media (min-width: $switchDesktopMin) {
  main {
    background: #ffffff;
    flex: 1;
    margin: 0 0 16px 0;

    > *:last-child {
      margin-bottom: 0 !important;
    }
  }
}

// Mobile
@media (max-width: $switchMobileMax) {
  main {
    background: #ffffff;
    padding: 10px;
    flex-grow: 0;
    flex-shrink: 0;

    > *:last-child {
      margin-bottom: 0 !important;
    }
  }
}

@import '../../themes/variables';

@media (min-width: $switchDesktopMin) {

  footer.footer-detail {
    margin: 0 0 16px 0;
    padding: 16px;
    background: #575757;
    border-radius: 3px;
    color: #fff;

    .row {
      > div {
        text-align: center;

        .col-icon {
          margin: 0;
          font-size: 36px;
          color: #5ab381;

          span {
            font-size: 36px;
          }
        }

        .col-content {
          h3 {
            font-size: 14px;
            font-weight: bold;
          }

          p {
            font-size: 14px;
            margin: 0;
          }
        }
      }
    }
  }

  footer.footer-more {
    background: #F2F4F4;
    border-radius: 3px;
    margin: 0 0 16px 0;
    border-bottom: none;
    padding: $spacerDesktop;
    position: relative;

    .copyright {
      position: absolute;
      bottom: 16px;
      right: 16px;
    }

    .footer-more-top {
      display: flex;
      gap: 16px;
      width: 100%;

      > div {
        flex-shrink: 0;
        flex-grow: 0;

        > strong {
          font-weight: 500;
          font-size: 16px;
          display: block;
          margin: 0 0 16px 0;
        }

        &.col-about {
          flex: 210px 0 0;
          margin: 0;
        }

        &.col-social-media {
          flex: 300px 0 0;
          margin: 0;

          a {
            margin: 0 6px 0 0;
            padding: 0;
            background: #5ab381;
            border-radius: 50%;
            height: 35px;
            width: 35px;
            display: inline-flex;
            justify-content: center;
            align-items: center;

            svg {
              color: #F2F4F4;
              height: 22px;
              width: 22px;
            }

            &:hover {
              svg {
                color: #ffffff;
              }
            }
          }
        }

        &.col-mobile-app {
          flex: 1 0 0;

          ul {
            list-style: none;
            display: flex;
            gap: 16px;
            margin: 0;
            padding: 0;

            li {
              display: block;
              margin: 0 0 5px 0;

              img {
                max-width: 100%;
              }
            }
          }
        }
      }
    }

    h4 {
      font-size: 14px;
      font-weight: bold;
    }

    ul {
      list-style: none;
      display: block;
      margin: 0;
      padding: 0;


      li {
        display: block;
        margin: 0 0 5px 0;
        padding: 0;
      }
    }

    .footer-social {
      font-size: 24px;
      margin: 0 0 16px 0;

      a, span {
        margin: 0 8px 0 0;
      }
    }
  }

  footer.footer-links {
    display: flex;
    align-items: center;
    margin: 0 0 16px 0;
    width: 100%;

    .payment {
      text-align: left;
      flex: 1;

      div {
        font-weight: 500;
        margin: 0 0 8px 0;
        color: #575757;
      }

      img {
        max-width: 100%;
        max-height: 32px;
      }
    }

    .copyright {
      flex: 1;
      text-align: right;
      font-size: 12px;
    }
  }
}

@import '../../themes/variables';

.combine-detail-wrapper {
  display: flex;
  width: 100%;
  gap: 16px;

  @media (max-width: $switchMobileMax) {
    flex-direction: column;
  }

  .combine-image-wrapper {
    flex: 0.5 0 0;
    position: relative;
    margin: 0 0 16px 0;
  }

  .combine-favorite {
    position: absolute;
    z-index: 2;
    top: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 0 1px 2px 1px rgb(0 0 0 / 10%);
    font-size: 18px;

    &.combine-favorite-active {
      color: #ef624d;
    }
  }

  .combine-detail {
    flex: 0.5 0 0;

    .combine-title {
      display: block;
      padding: 0 24px 0 0;
      margin: 0 0 8px 0;
    }

    .combine-style {
      display: block;
      margin: 0 0 8px 0;
    }

    .combine-price {
      display: flex;
      margin: 0 0 8px 0;

      .combine-price-min {
      }

      .combine-price-max {
      }

      .combine-discount {
        color: #ef624d;
        margin: 0 8px 0 0;
      }
    }

    .combine-rate {
      margin: 0 0 8px 0;
    }

    .form-check-input {
      width: 24px;
      height: 24px;
      margin: 0;
    }

    .combine-option-title {
      margin: 0 0 8px 0;
    }

    .combine-price-initial {
      text-decoration: line-through;
      margin: 0 8px 0 0;
    }

    .combine-price-final {
    }

    //.combine-discount {
    //  background: #ef624d;
    //  position: absolute;
    //  left: 0;
    //  top: 0;
    //  writing-mode: vertical-lr;
    //  text-orientation: mixed;
    //  padding: 5px 0px;
    //  border-bottom-right-radius: 3px;
    //  border-top-left-radius: 3px;
    //  color: #fff;
    //  transform: rotate(180deg);
    //}

    .combine-add-to-cart {
      margin: 0 0 16px 0;
      display: flex;

      .input-group {
        width: 100px;
        margin: 0 8px 0 0;

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
        }
      }

      > .btn {
        margin: 0 8px 0 0;
      }
    }

    .combine-products {
      > * {
        margin: 0 0 16px 0;
      }

      .combine-product {
        position: relative;

        &.cart-product-deleted {
          opacity: 0.6;
        }

        > div {
          display: flex;
          gap: 16px;

          .combine-product-checkbox {
            flex: 24px 0 0;
          }

          .product {
            flex: 1;
          }
        }

        .combine-product-add-back {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(0, 0, 0, 0.2);
          border-radius: 3px;
        }
      }
    }

    .combine-warning {
      color: #ef624d;
      margin: 16px 0 0 0;
    }

    .combine-out-of-stock-warning {
      color: #ef624d;
    }
  }

  .combine-variant {
    margin: 0 0 16px 0;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 8px;

      li {
        display: block;
        margin: 0;
        padding: calc((100% - 40px) / 6 * 1.5) 0 0 0;
        width: calc((100% - 40px) / 6);
        position: relative;
        cursor: pointer;

        div {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          border: 1px solid #cccccc;
          border-radius: 3px;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        &.combine-variant-active {
          div {
            border-color: #5ab381;
          }
        }

        &.combine-variant-more {
          div {
          }
        }
      }
    }
  }
}

.combine-detail-more {
  margin: 0 0 16px 0;
  font-size: 14px;

  h2 {
    border-bottom: 3px solid #5ab381;
    padding: 8px 0;
    font-size: 16px;
    display: block;
  }

  ul {
    li {
      margin: 0 0 8px 0;

      &:last-child {
        margin: 0;
      }
    }
  }
}

@media (max-width: $switchMobileMax) {
  .modal-combine {
    position: relative;

    .btn-back {
      position: absolute;
      top: 0;
      left: 0;
    }

    .combine-detail-wrapper {
      .combine-image-wrapper {
        margin: -14px -14px 0 -14px;
        border-radius: 0;

        img {
          border-radius: 0;
        }
      }
    }
  }

  .modal-combine-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

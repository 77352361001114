@import '../../themes/variables';

@media (min-width: $switchDesktopMin) {

  .header-top {
    position: relative;
    background: #f2f4f4;
    display: flex;
    font-size: 12px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    padding: 5px $spacerDesktop;
    margin: 0 -16px 16px;

    .header-top-left {
      flex: 1;

      a {
        margin: 0 $spacerDesktop 0 0;
      }
    }

    .header-top-right {
      a {
        margin: 0 0 0 $spacerDesktop;
      }

      span {
        font-size: 11px;
      }
    }
  }

  .header-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background: #ffffff;
    margin: 0 0 $spacerDesktop 0;

    .header-menu-title {
      margin: 0 $spacerDesktop 0 0;
      flex: 280px 0 0;
      display: flex;
      justify-content: flex-start;

      img {
        margin: 0 8px 0 0;
      }

      a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-decoration: none;

        font-size: 24px;
        line-height: 24px;
      }
    }

    .header-menu-search {
      flex: 1;
      position: relative;
    }

    .header-menu-button {
      display: flex;

      .dropdown {
        .dropdown-menu {
          right: 0;
        }

        &:hover .dropdown-menu {
          display: block;
        }
      }

      flex: 280px 0 0;
      justify-content: flex-end;

      > a,
      > .dropdown {
        margin: 0 0 0 16px;
        position: relative;
        text-align: center;

        span.material-icons-outlined {
          display: block;
          text-align: center;
        }

        svg {
          font-size: 24px;
        }

        sup {
          background: #ef624d;
          position: absolute;
          top: -8px;
          right: -8px;
          border-radius: 50%;
          height: 20px;
          width: 20px;
          display: flex;
          justify-content: center;
          justify-items: center;
          align-items: center;
          align-content: center;
          color: #ffffff;
          text-decoration: none;
        }

        &:hover {
          color: #5ab381;
        }

        &.active {
          color: #5ab381;
        }
      }
    }
  }
}


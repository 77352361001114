@import 'themes/variables';

$grid-gutter-width: 1rem;
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1200px
);

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
//@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
//@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
//@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
//@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
//@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
//@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
//@import "~bootstrap/scss/popover";
//@import "~bootstrap/scss/carousel";
//@import "~bootstrap/scss/spinners";
//@import "~bootstrap/scss/offcanvas";
//@import "~bootstrap/scss/placeholders";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";
// scss-docs-end import-stack

//@import '~bootstrap/scss/bootstrap';
//@import '~bootstrap/dist/css/bootstrap.min.css';

// Not sure about important part.
.modal, .modal-backdrop {
  z-index: 1000 !important;
}

.modal-title.h4 {
  margin: 0;
}

#kombin-customer {
  width: 100%;
  min-height: 100%;
  display: block;

  .container, .container-fluid {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

@media (max-width: $switchMobileMax) {
  body {
    overflow: hidden;
  }

  #kombin-customer {
    > div {
      height: 100%;
      display: flex;
      flex-direction: column;

      header {
        flex-basis: 64px;
        flex-grow: 0;
        flex-shrink: 0;
      }

      main {
        flex-grow: 0;
        flex-shrink: 0;
        overflow: auto;
      }

      footer {
        flex-basis: 60px;
        flex-grow: 0;
        flex-shrink: 0;
      }
    }

    a {
      -webkit-touch-callout: none;
    }
  }

  .modal-header {
    background: #f2f2f6;
  }

  .modal-footer {
    background: #f2f2f6;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body {
  height: 100%;
  font-size: 14px;
}

.form-check-input:checked {
  background-color: #5ab381;
  border-color: #5ab381;
}

.bg-kombin {
  background-color: #5ab381;
}

.btn-kombin {
  background-color: #5ab381;
  color: #ffffff;

  &:hover {
    color: #ffffff;
    opacity: 0.9;
  }
}

.btn-kombin-secondary-outline {
  border-color: #5ab381;
  background-color: #ffffff;
  color: #5ab381;

  &:hover {
    opacity: 0.9;
  }
}

.btn-facebook {
  background: #3b5998;
  color: #ffffff;
}

.btn-twitter {
  background: #1DA1F2;
  color: #ffffff;
}

.btn-google {
  background: #ff3e30;
  color: #ffffff;
}

.btn-apple {
  background: #a2aaad;
  color: #ffffff;
}

.list-group-item.active {
  background-color: #707070;
  border-color: #707070;
}

h1 {
  font-size: 24px;
  margin: 0 0 16px 0;
}

h2 {
  font-size: 20px;
  margin: 0 0 16px 0;
}

h3 {
  font-size: 18px;
  margin: 0 0 16px 0;
}

h4 {
  font-size: 16px;
  margin: 0 0 16px 0;
}

h5 {
  font-size: 14px;
  margin: 0 0 16px 0;
}

a {
  color: #333;
  text-decoration: none;

  &:hover {
    color: $link-hover-color;
  }
}

.text-pre-line {
  white-space: pre-line;
}

.text-kombin {
  color: $link-hover-color !important;;
}

.border-kombin {
  border-color: $link-hover-color !important;
}

.form-feedback {
  width: 100%;
  margin: 0 0 16px 0;
  color: #ef624d;
  font-size: .875em;
}

.material-icons,
.material-icons-outlined {
  opacity: 0;
}

body.fonts-loaded {
  .material-icons,
  .material-icons-outlined {
    opacity: 1;
  }
}

body > iframe {
  display: none;
}

.container {
  max-width: 1024px;
}

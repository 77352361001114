@import '../../themes/variables';

@media (max-width: $switchMobileMax) {
  footer.footer-bottom {
    width: 100%;
    z-index: 2;
    border-top: 1px solid #cccccc;
    background: #f2f2f6;

    ul {
      list-style: none;
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: 0 10px;

      li {
        margin: 0;
        padding: 0;
        flex: 1;

        a {
          font-size: 10px;
          display: block;
          padding: 8px 0;
          text-align: center;
          position: relative;
          color: #575757;

          &.active {
            color: $link-hover-color;
          }

          span.material-icons,
          span.material-icons-outlined {
            font-size: 24px;
          }

          span {
            display: block;
          }

          sup {
            position: absolute;
            border-radius: 50%;
            padding: 5px;
            font-size: 11px;
            background: #ef624d;
            top: 2px;
            right: 2px;
            height: 16px;
            width: 16px;
            display: flex;
            justify-content: center;
            justify-items: center;
            align-items: center;
            align-content: center;
            color: #ffffff;
            text-decoration: none;

            &.count-favorite {
              background: #5ab381;
            }
          }
        }
      }
    }
  }
}

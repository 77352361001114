@import '../themes/variables';

@media (max-width: $switchMobileMax) {
  .home-brands {
    margin: 0 0 8px 0;
    width: 100%;

    display: flex;
    overflow-x: auto;
    flex-grow: 1;

    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      display: none;
    }

    a {
      width: calc((100% - 3 * 8px - 40px) / 4);
      display: block;
      margin: 0 8px 0 0;
      scroll-snap-align: start;
      flex-shrink: 0;

      img {
        border-radius: 50%;
        width: 100%;
        height: auto;
      }
    }
  }
}

@media (min-width: $switchDesktopMin) {
  .home-brands {
    display: flex;
    gap: 16px;
    margin: 0 0 16px 0;
    overflow: auto;
    width: 100%;
    min-height: 70px;

    a {
      flex: 1;
      display: block;

      img {
        border-radius: 50%;
        width: 100%;
        height: auto;
      }
    }
  }
}

@media (max-width: $switchMobileMax) {
  .home-banners {
    display: flex;
    gap: 8px;
    margin: 0 0 16px 0;
    flex-direction: column;

    img {
      border-radius: 3px;
      width: 100%;
      height: auto;
    }
  }
}

@media (min-width: $switchDesktopMin) {
  .home-banners {
    display: flex;
    gap: 16px;
    margin: 0 0 16px 0;

    > a {
      flex-basis: calc(50% - 8px);
      flex-shrink: 0;
      flex-grow: 0;

      img {
        border-radius: 3px;
        width: 100%;
        height: auto;
      }
    }
  }
}

@import '../../themes/variables';

.combine {
  position: relative;
  overflow: hidden;
  background: #ffffff;
  border-radius: 3px;
  box-sizing: border-box;

  .combine-image-wrapper {
    border: 1px solid rgba(0, 0, 0, .125);
    width: 100%;
    padding-top: 150%; /* Altın Oran */
    position: relative;

    .combine-image {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      img {
        display: none;
        transition: all 400ms;
        max-width: 100%;
        max-height: 100%;

        &.active {
          display: block;
        }
      }
    }

    .combine-image-variants {
      width: 100%;
      position: absolute;
      bottom: 5px;
      left: 0;
      display: none;
      justify-content: center;
      align-items: center;
      gap: 3px;

      > div {
        display: block;
        width: 6px;
        height: 6px;
        background: #cccccc;
        border-radius: 50%;

        &.active {
          background-color: #ef624d;
        }
      }
    }
  }

  @media (min-width: $switchDesktopMin) {
    &:hover .combine-image-wrapper {
      border-color: #d9d9d9;

      .combine-image {
        img {
          max-width: 105%;
          max-height: 105%;
        }
      }

      .combine-image-variants {
        display: flex;
      }
    }
  }

  .combine-detail {
    padding: 8px 0;

    .combine-style {
      display: block;;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: bold;
    }

    .combine-title {
      display: block;;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .combine-price {
    display: flex;
    margin: 0 0 8px 0;

    .combine-price-min {
    }

    .combine-price-max {
    }

    .combine-discount {
      background: #ef624d;
      position: absolute;
      left: 0;
      top: 0;
      writing-mode: vertical-lr;
      text-orientation: mixed;
      padding: 5px 0px;
      border-bottom-right-radius: 3px;
      border-top-left-radius: 3px;
      color: #fff;
      transform: rotate(180deg);
    }
  }

  .combine-rate {
  }

  .combine-favorite {
    position: absolute;
    z-index: 2;
    top: 10px;
    right: 10px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 0 1px 2px 1px rgb(0 0 0 / 10%);
    font-size: 18px;

    &.combine-favorite-active {
      color: #ef624d;
    }
  }

  .combine-quick {
    position: absolute;
    bottom: 16px;
    left: 50%;
    margin: 0 0 0 -50px;
    display: none;
  }

  @media (min-width: $switchDesktopMin) {
    &:hover .combine-quick {
      display: block;
    }
  }

  .combine-variant {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      gap: 4px;

      li {
        display: block;
        margin: 0;
        padding: calc((100% - 16px) / 5) 0 0 0;
        width: calc((100% - 16px) / 5);
        position: relative;
        cursor: pointer;

        div {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          border: 1px solid #cccccc;
          border-radius: 50%;

          img {
            max-width: 90%;
            max-height: 90%;
          }
        }

        &.combine-variant-active {
          div {
            border-color: #5ab381;
          }
        }

        &.combine-variant-more {
          div {
          }
        }
      }
    }
  }

  .combine-add-to-cart {
    margin: 0 0 8px 0;
    display: flex;

    .input-group {
      width: 100px;
      margin: 0 8px 0 0;

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type=number] {
        -moz-appearance: textfield;
      }
    }
  }

  .combine-warning {
    color: #ef624d;
    margin: 0 0 8px 0;
  }

  .combine-out-of-stock-warning {
    color: #ef624d;
    margin: 0 0 8px 0;
  }

}

.combine-horizontal {
  display: flex;
  flex-direction: row;
  gap: 16px;

  .combine-image-wrapper {
    flex-basis: 30%;
    flex-shrink: 0;
    padding-top: calc(30% * 1.5); /* Altın Oran */
  }

  .combine-favorite {
    top: 0;
    right: 0;
  }

  .combine-detail {
    flex: 1;

    .combine-title {
      white-space: pre-wrap;
    }
  }

  .combine-option-title {
    margin: 0 0 8px 0;
  }

  .combine-variant {
    margin: 0 0 8px 0;

    ul {
      li {
        padding: calc((100% - 28px) / 8) 0 0 0;
        width: calc((100% - 28px) / 8);
      }
    }
  }
}

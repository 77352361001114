.header-menu-search-desktop {
  position: absolute;
  top: 40px;
  left: 0;
  margin: 0;
  width: 100%;
  max-height: 500px;
  background: #fff;
  box-shadow: 0 2px 10px 1px rgb(0 0 0 / 5%);
  z-index: 103;
  padding: 8px;
  border-radius: 3px;
  border: 2px solid green;
  transition: height 0.3s ease;
  overflow: auto;
}

@import '../../themes/variables';

.nav-breadcrumb {
  background: #ffffff;
  padding: 0;
  @media (min-width: $switchDesktopMin) {
    margin: 0 0 $spacerDesktop 0;
  }

  @media (max-width: $switchMobileMax) {
    margin: 0 0 $spacerMobile 0;
  }

  .breadcrumb {
    margin: 0;
  }
}

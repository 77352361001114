.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 20px 0;

  > span {
    margin: 10px 0 0 0;
  }
}

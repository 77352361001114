.cookie-warning {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px 0;
  background: #eeeeee;
  border-top: #666666;
  transition: all 400ms ease-in;
  z-index: 2;

}

.combine-viewed {
  margin: 0 0 16px 0;
  position: relative;
  height: 390px;

  h4 {
    margin: 0 0 16px 0;
  }

  .btn-viewed-clean {
    position: absolute;
    top: 0;
    right: 0;
  }
}

@import '../../themes/variables';

.combine-list-wrapper {
  position: relative;


  .combine-list-horizontal {
    display: flex;
    gap: 16px;
    width: 100%;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      display: none;
    }

    > .combine {
      width: calc((100% - 4 * 16px - 64px) / 5);
      scroll-snap-align: start;
      flex-shrink: 0;
      flex-grow: 0;
      counter-increment: item;
    }

    @media (max-width: $switchMobileMax) {
      > .combine {
        width: calc((100% - 16px - 32px) / 2);
      }
    }
  }

  .combine-list-horizontal-prev {
    position: absolute;
    top: 73px;
    left: 0;
    font-size: 22px;
    background: #ffffff;
    width: 40px;
    height: 100px;
    border: 1px solid #eee;
    border-left: none;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    visibility: hidden;
    opacity: 0;
    transition: all 400ms ease;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .combine-list-horizontal-next {
    position: absolute;
    top: 73px;
    right: 0;
    font-size: 22px;
    background: #ffffff;
    width: 40px;
    height: 100px;
    border: 1px solid #eee;
    border-right: none;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    visibility: hidden;
    opacity: 0;
    transition: all 400ms ease;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: $switchDesktopMin) {
    &:hover {
      .combine-list-horizontal-prev {
        visibility: visible;
        opacity: 0.9;

        &:hover {
          opacity: 1;
        }
      }

      .combine-list-horizontal-next {
        visibility: visible;
        opacity: 0.9;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

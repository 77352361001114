@import '../../themes/variables';

.product {
  position: relative;
  overflow: hidden;
  background: #ffffff;
  border-radius: 3px;
  box-sizing: border-box;

  .product-image-col {
    width: 100%;
  }

  .product-image-wrapper {
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 3px;
    overflow: hidden;
    padding-top: calc(150% - 3px);
    position: relative;
    width: 100%;
    box-sizing: border-box;

    .product-image {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      img {
        display: none;
        transition: all 400ms;
        max-width: 100%;
        max-height: 100%;

        &.active {
          display: block;
        }
      }
    }

    .product-image-variants {
      width: 100%;
      position: absolute;
      bottom: 5px;
      left: 0;
      display: none;
      justify-content: center;
      align-items: center;
      gap: 3px;

      > div {
        display: block;
        width: 6px;
        height: 6px;
        background: #cccccc;
        border-radius: 50%;

        &.active {
          background-color: #ef624d;
        }
      }
    }
  }

  @media (min-width: $switchDesktopMin) {
    &:hover .product-image-wrapper {
      border-color: rgba(0, 0, 0, .25);
      transition: all 400ms;

      .product-image {
        img {
          max-width: 105%;
          max-height: 105%;
        }
      }

      .product-image-variants {
        display: flex;
      }
    }
  }

  .product-detail {
    padding: 8px 0;

    .product-brand {
      display: block;;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: bold;
    }

    .product-title {
      display: block;;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .product-price {
    display: flex;
    margin: 0 0 8px 0;

    .product-price-initial {
      text-decoration: line-through;
      margin: 0 8px 0 0;
    }

    .product-price-final {
    }

    .product-discount {
      background: #ef624d;
      position: absolute;
      left: 0;
      top: 0;
      writing-mode: vertical-lr;
      text-orientation: mixed;
      padding: 5px 0px;
      border-bottom-right-radius: 3px;
      border-top-left-radius: 3px;
      color: #fff;
      transform: rotate(180deg);
    }
  }

  .product-rate {
  }

  .product-favorite {
    position: absolute;
    z-index: 2;
    top: 10px;
    right: 10px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 0 1px 2px 1px rgb(0 0 0 / 10%);
    font-size: 18px;

    &.product-favorite-active {
      color: #ef624d;
    }
  }

  .product-quick {
    position: absolute;
    bottom: 16px;
    left: 50%;
    margin: 0 0 0 -50px;
    display: none;
  }

  @media (min-width: $switchDesktopMin) {
    &:hover .product-quick {
      display: block;
    }
  }

  .product-variant {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      gap: 4px;

      li {
        display: block;
        margin: 0;
        padding: calc((100% - 16px) / 5) 0 0 0;
        width: calc((100% - 16px) / 5);
        position: relative;
        cursor: pointer;

        div {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          border: 1px solid #cccccc;
          border-radius: 50%;

          img {
            max-width: 90%;
            max-height: 90%;
          }
        }

        &.product-variant-active {
          div {
            border-color: #5ab381;
          }
        }

        &.product-variant-more {
          div {
          }
        }
      }
    }
  }

  .product-size {
    margin: 0 0 16px 0;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 8px;

      li {
        display: block;

        &.product-size-active {
        }

        &.product-size-more {
          div {
          }
        }
      }
    }
  }

  .btn-size-secondary {
    border-color: #9a9a9a;
    background-color: #ffffff;
    color: rgb(33, 37, 41);
    min-width: 28px;
    padding: 0;
    text-align: center;
    height: 28px;
    font-size: 12px;
    outline: none;
    box-shadow: none;

    &:hover {
      color: rgb(33, 37, 41);
    }
  }

  .btn-size {
    border-color: #5ab381;
    background-color: #5ab381;
    color: #ffffff;
    min-width: 28px;
    padding: 0;
    text-align: center;
    height: 28px;
    font-size: 12px;
    outline: none;
    box-shadow: none;

    &:hover {
      color: #ffffff;
    }
  }

  .product-add-to-cart {
    margin: 0 0 8px 0;
    display: flex;

    .input-group {
      width: 100px;
      margin: 0 8px 0 0;

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type=number] {
        -moz-appearance: textfield;
      }
    }
  }

  .product-warning {
    color: #ef624d;
    margin: 0 0 8px 0;
  }

  .product-out-of-stock-warning {
    color: #ef624d;
    margin: 0 0 8px 0;
  }

}

.product-horizontal {
  display: flex;
  flex-direction: row;
  gap: 16px;

  .product-image-col {
    flex-basis: 30%;
    flex-shrink: 0;
  }

  .product-favorite {
    top: 0;
    right: 0;
  }

  .product-detail {
    flex: 1;

    .product-title {
      white-space: pre-wrap;
    }
  }

  .product-option-title {
    margin: 0 0 8px 0;
  }

  .product-variant {
    margin: 0 0 8px 0;

    ul {
      li {
        padding: calc((100% - 28px) / 8) 0 0 0;
        width: calc((100% - 28px) / 8);
      }
    }
  }
}

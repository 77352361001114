@import '../../themes/variables';

@media (max-width: $switchMobileMax) {
  header {
    left: 0;
    width: 100%;
    z-index: 7;
    padding: 0 10px;
    background: #f2f2f6;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 63px;
  }

  .header-title {
    font-size: 15px;
    font-weight: bold;
    margin: 0;

    a {
      font-weight: bold;
      margin: 0 8px 0 0;
    }
  }
}

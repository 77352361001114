@import '../../themes/variables';

@media (min-width: $switchMobileMax) {

  .navigation {
    margin: 0 -16px 16px -16px;
    position: relative;

    &.navigation-show + .navigation-backdrop {
      opacity: 1;
      visibility: visible;
      display: block;
    }

    .nav {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: stretch;
      align-content: center;
      justify-content: space-around;
      margin: 0;
      border-bottom: 1px solid #ced4da;

      a {
        text-align: center;
        flex: 1;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 9px $spacerDesktop 4px;
        text-transform: uppercase;
        text-decoration: none;
        justify-content: center;
        white-space: nowrap;
        border-bottom: 3px solid #ffffff;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
      }

      .nav-active {
        a {
          color: $link-hover-color;
          border-bottom: 3px solid $link-hover-color;
        }
      }

      & > div:hover,
      .nav-hover {
        a, span {
          background: #f2f4f4;
          border-bottom: 3px solid #f2f4f4;
        }
      }
    }

    .nav-more {
      background: #f2f4f4;
      position: absolute;
      z-index: 102;
      left: 0;
      top: 36px;
      height: 0;
      width: 100%;
      border-top: none;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      overflow: hidden;
      transition: height 300ms ease-in-out;

      &.nav-more-show {
        padding: 16px;
        height: 330px;
      }
    }
  }

  .nav-combine,
  .nav-category {
    ul {
      display: block;
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: block;
        margin: 0 0 5px 0;
        padding: 0;
        line-height: 16px;
      }
    }

    .navigation-image {
      height: 300px;
      display: block;

      img {
        float: right;
        width: auto;
        max-height: 100%;
        max-width: 100%;
      }
    }
  }

  .navigation-backdrop {
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    z-index: 101;
    left: 0;
    top: 141px;
    width: 100%;
    height: 100%;
    display: none;
    opacity: 0;
    visibility: hidden;
    animation: fadein 200ms;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
